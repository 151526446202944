// common
const GroupList = resolve => require(["@/components/event/_group_list.vue"], resolve)

export default {
    props: ['isAllowRegister', 'registerStatusText', 'individual', 'contentStyle', 'leaderBoardSetting'],
    components: {
        GroupList
    },
    data: function() {
        return {
            // 下拉選單
            counties: [],
            districts: [],
            schoolList: [],
            // 活動資料
            eventInfo: {
                id:'',
                selectedGroup: {
                    id: '',
                    name: ''
                },
                selectedCounty: '',
                selectedDistrict: '',
                selectedSchoolId: '',
                inputSchoolName: '',
                selectedGrade: '',
                inputName: ''
            },
            oldEventInfo: {
                selectedGroup: {
                    id: '',
                    name: ''
                }
            },

            isShowGroupList: false,
            isRenderGroupList: 0,

            isSetDataReady: {
                getCountyDistrict: false
            },

            isPostingApi: {
                adding: false,  // 報名
            }
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.common.userInfo;
        },
        grades() {
            let list = [
                {
                    value: 1,
                    text: '一年級',
                },
                {
                    value: 2,
                    text: '二年級',
                },
                {
                    value: 3,
                    text: '三年級',
                },
                {
                    value: 4,
                    text: '四年級',
                },
                {
                    value: 5,
                    text: '五年級',
                },
                {
                    value: 6,
                    text: '六年級',
                },
                {
                    value: 7,
                    text: '七年級',
                },
                {
                    value: 8,
                    text: '八年級',
                },
                {
                    value: 9,
                    text: '九年級',
                },
                {
                    value: 10,
                    text: '十年級',
                },
                {
                    value: 11,
                    text: '十一年級',
                },
                {
                    value: 12,
                    text: '十二年級',
                },
            ];

            let result = [];
            if (this.individual.gradeLow != 0 &&
                this.individual.gradeHeigh != 0)
            for (let el of list) {
                if (el.value >= this.individual.gradeLow &&
                    el.value <= this.individual.gradeHeigh) {
                        result.push(el);
                }
            }
            else {
                result = list;
            }

            return result;
        },
        countyDistrict() {
            return this.$store.state.common.countyDistrict;
        },
        isEventInfoDisable() { // 檢查報名欄位是否有沒填的
            if (!this.eventInfo.selectedGroup.name ||
                !this.eventInfo.selectedCounty ||
                !this.eventInfo.selectedDistrict ||
                !this.eventInfo.inputSchoolName ||
                !this.eventInfo.inputName) {
                    return true;
                }
            return false;
        },
    },
    created: function() {
        this.initialize();
        if (!this.$parent.$parent.checkUserLogin()) {
            return;
        }
        else {
            // 活動ID 2 沒有團隊報名
            if (this.userInfo.permission === 'user' || ['2'].includes(this.$route.params.alias)) {
                this.$router.push('/event/' + this.$route.params.alias + '/charts/team');
            }
        }
        this.eventInfo.selectedGrade = this.individual.grade ? { value: this.individual.grade, text: this.grades[this.individual.grade - 1].text }
                    : this.userInfo.grade ? { value: this.userInfo.grade.replace(/^0+/, ''), text: this.grades[this.userInfo.grade.replace(/^0+/, '') - 1].text } : '';
    },
    methods: {
        async initialize() {
            this.$store.dispatch('common/getCountyDistrict')
                .then(() => {
                    this.isSetDataReady.getCountyDistrict = true;
                    let tempCounties = this.leaderBoardSetting.counties.length > 0 ? this.leaderBoardSetting.counties : this.countyDistrict.counties;
                    this.counties = tempCounties;
                    this.schoolList = [];
                })
                .catch(error => console.error('catched error: ' + error));
        },
        getDistrict(){
            this.eventInfo.selectedDistrict = '';
            this.districts = [];
            this.districts = this.countyDistrict.districts[this.eventInfo.selectedCounty];
        },
        getSchoolList() {
            let params = {
                    county: this.eventInfo.selectedCounty,
                    district: this.eventInfo.selectedDistrict
                }

            this.$httpRequest.get('/api/user/get_school_list', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.schoolList = result;
                        }
                    }
                })
                .catch(function (error) {
                    console.error('catched error: ' + error);
                });
        },
        chooseSchool(item) {
            this.schoolList = [];
            this.eventInfo.selectedSchoolId = item.school_id;
            this.eventInfo.inputSchoolName = item.name;
        },
        addEvent() {
            if (!this.$parent.$parent.checkUserLogin()) {
                return;
            }

            if (this.$route.params.alias == '1' && !['桃園市', '新竹市', '新竹縣', '雲林縣', '宜蘭縣'].includes(this.userInfo.county)) {
                this.$store.dispatch('common/setAlert', { msg: '此活動僅限桃園市、新竹市、新竹縣、雲林縣、宜蘭縣參加', status: 'danger', duration: '5000' });
                return;
            }

            if (this.$route.params.alias == '3' && this.userInfo.county != '桃園市') {
                this.$store.dispatch('common/setAlert', { msg: '此活動僅限桃園市參加', status: 'danger', duration: '5000' });
                return;
            }

            if (!this.individual.allowRegister) {
                this.$store.dispatch('common/setAlert', { msg: '此活動僅限有購買課程才能參加', status: 'danger' });
                return;
            }

            if (this.isEventInfoDisable || this.isPostingApi.addEvent) {
                return;
            }

            let params = {
                    alias: this.$route.params.alias,
                    isRegister: true,
                    groupInfo: {
                        groupId: this.eventInfo.selectedGroup.id,
                        name: this.eventInfo.inputName
                    },
                    grade: this.eventInfo.selectedGrade.value,
                }

            if (this.eventInfo.selectedSchoolId != 0) {
                params.groupInfo.schoolId = this.eventInfo.selectedSchoolId;
            }

            this.isPostingApi.addEvent = true;

            this.$httpRequest.post('/api/event/register_event', params)
                .then(response => {
                    this.isPostingApi.addEvent = false;
                    if (response.data.state == 'OK') {
                        let result = response.data.result;
                        if (result) {
                            this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                            this.$router.push('/event/' + this.$route.params.alias + '/my-register');
                        }
                    }
                })
                .catch(error => {
                    console.error('catched error: ' + error);
                });
        },
    }
}