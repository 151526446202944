<template>
    <Dialogue
        :id="'writingReviewDescriptionDialogue'"
        :size="480"
        :modal-body-version="'v2'"
    >
        <template v-slot:titleText>
            作文批改說明
        </template>
        <template v-slot:bodyContent>
            <div class="default-dialogue-content">
                <ol class="review-description">
                    <li>總評（整體表現）：請參閱大考中心英文作文批改的計分方式（A 約為「優」、B 約為「可」，以此類推）。因大考中心以區間公告作文總分，如113年，特優(19-20分)、優(15-18分)、可(10-14分)、差(5-9分)、劣 (0-4分)，故本系統之評分結果僅作參考。</li>
                    <li>逐句批改：批改文法或用字錯誤，及正確的情況下，提升清晰度或語言自然度；說明時，不會列出所有錯誤（詳細內容請參考修改後的內容）。</li>
                    <li>原文優化：在不大幅度修改原文脈絡的前提下(特殊情況除外)，用稍高於原文的字彙與文法程度進行優化。優化後的內容若少於原文，可能是原文較冗長；反之，若多於原文，可能是為了增加連貫性或流暢度而新增字句。</li>
                    <li>重要提醒：現存AI在運算中可能產生潛在問題，故批改結果僅供參考，若有進一步的疑問請向專業人士諮詢或聯繫本平台。</li>
                </ol>
            </div>
        </template>
    </Dialogue>
</template>

<script>
// common
const Dialogue = resolve => require(["@/components/common/dialogue_client.vue"], resolve)

export default {
    components: {
        Dialogue,
    },
}
</script>

<style lang="scss" src="@/assets/scss/components/article/practicing/write/_review_description.scss" scoped />
