<template>
    <Dialogue
        :id="'translationReviewDescriptionDialogue'"
        :size="480"
        :modal-body-version="'v2'"
    >
        <template v-slot:titleText>
            翻譯批改說明
        </template>
        <template v-slot:bodyContent>
            <div class="default-dialogue-content">
                <ol class="review-description">
                    <li>整體表現：<br>A 表示儘管有些許錯誤，依然幾乎完全傳達題目的語意。<br>B 表示可能有幾個錯誤，但不會顯著地影響題目的語意。<br>C 表示有多個明顯影響語意的錯誤，或此作答已對題目進行不必要的更改（如增譯、改譯或漏譯），導致作答與題目在語意上有一定的落差。<br>D 表示可能存在導致無法清楚表達題目語意的錯誤（如題目為否定，但作答譯為肯定）。</li>
                    <li>修正後：批改文法或用字錯誤，及正確的情況下，提升清晰度或語言自然度。</li>
                    <li>說明：呈現的是較明顯的問題，但不會列出所有錯誤（詳細內容請參考修改後的內容）。</li>
                    <li>現存AI在運算中可能產生潛在問題，故批改結果僅供參考，若有進一步的疑問請向專業人士諮詢或聯繫本平台。</li>
                </ol>
            </div>
        </template>
    </Dialogue>
</template>

<script>
// common
const Dialogue = resolve => require(["@/components/common/dialogue_client.vue"], resolve)

export default {
    components: {
        Dialogue,
    },
}
</script>

<style lang="scss" src="@/assets/scss/components/article/practicing/write/_review_description.scss" scoped />
