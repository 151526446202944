import { render, staticRenderFns } from "./_writing_review_description.vue?vue&type=template&id=3c7a6c62&scoped=true&"
import script from "./_writing_review_description.vue?vue&type=script&lang=js&"
export * from "./_writing_review_description.vue?vue&type=script&lang=js&"
import style0 from "@/assets/scss/components/article/practicing/write/_review_description.scss?vue&type=style&index=0&id=3c7a6c62&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c7a6c62",
  null
  
)

export default component.exports